import {
  Alternative,
  Button,
  Card,
  CheckboxGroup,
  Icon,
  RequiredValidator,
  Select,
  SNACKBAR_TYPES,
  Toggle,
} from "@lysaab/ui-2";
import { useCallback, useState } from "react";
import AnimateHeight from "react-animate-height";
import {
  CustomerType,
  AccountType,
  Browser,
  Device,
  Filters,
  Message,
} from "../../data/dataMessages";
import { MessageEditor } from "../messageEditor/MessageEditor";
import { PathsEditor } from "../paths/PathsEditor";
import "./MessageListItem.scss";
import { MessageListItemHeader } from "./MessageListItemHeader";

const MESSAGE_STATUSES = Object.entries(SNACKBAR_TYPES).map(
  ([text, value]) => ({
    text,
    value,
  })
);

const CUSTOMER_TYPES = Object.entries(CustomerType).map(([text, value]) => ({
  text: `Show for ${text}`,
  value,
}));

const ACCOUNT_TYPES = Object.entries(AccountType).map(([text, value]) => ({
  text: `Show for ${text}`,
  value,
}));

const BROWSER_TYPES = Object.entries(Browser).map(([text, value]) => ({
  text: `Show in ${text}`,
  value,
}));

const DEVICE_TYPES = Object.entries(Device).map(([text, value]) => ({
  text: `Show on ${text}`,
  value,
}));

interface Props {
  message: Message;
  update: (message: Message) => void;
  remove: (message: Message) => void;
}

export const MessageListItem: React.VFC<Props> = ({
  message,
  update,
  remove,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { enabled, status, filter, paths } = message;

  const setEnabled = useCallback(
    (enabled: boolean) => {
      const newMessage = { ...message, enabled };
      update(newMessage);
    },
    [message, update]
  );

  const setStatus = useCallback(
    (status: SNACKBAR_TYPES) => {
      const newMessage = { ...message, status };
      update(newMessage);
    },
    [message, update]
  );

  const setMessage = useCallback(
    (text: string) => {
      // TODO: Perhaps debounce this in the future?
      const newMessage = { ...message, message: text };
      update(newMessage);
    },
    [message, update]
  );

  const setPaths = useCallback(
    (paths: string[]) => {
      const newMessage = { ...message, paths };
      update(newMessage);
    },
    [message, update]
  );

  const setFilter = useCallback(
    <T extends unknown>(selected: Alternative<T>[], key: keyof Filters) => {
      const newMessage = {
        ...message,
        filter: {
          ...message.filter,
          [key]: selected.map(({ value }) => value),
        },
      };
      update(newMessage);
    },
    [message, update]
  );

  return (
    <div className="message-list-item">
      <Card className="mb-4">
        <MessageListItemHeader
          isOpen={isOpen}
          enabled={enabled}
          message={message.message}
          type={status}
          toggle={setIsOpen}
        />
        <AnimateHeight animateOpacity={true} height={isOpen ? "auto" : 0}>
          <div className="pt-4 px-5 pb-4">
            <div className="row">
              <div className="col">
                <div className="lysa-ui-input-group">
                  <label>Message active</label>
                  <Toggle
                    value={enabled}
                    onChange={() => setEnabled(!enabled)}
                  />
                </div>
              </div>
              <div className="col">
                <Select
                  placeholder="Select an item"
                  alternatives={MESSAGE_STATUSES}
                  value={MESSAGE_STATUSES.find(({ value }) => value === status)}
                  onChange={({ value }) => setStatus(value)}
                  label="Message type"
                  validators={[new RequiredValidator("Required")]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col lysa-ui-input-group">
                <MessageEditor
                  value={message.message}
                  label="Message content"
                  onChange={setMessage}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <PathsEditor value={paths} onChange={setPaths} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CheckboxGroup
                  header="Customers"
                  onChange={(val) => setFilter(val, "types")}
                  values={CUSTOMER_TYPES.filter(({ value }) =>
                    filter.types.includes(value)
                  )}
                  alternatives={CUSTOMER_TYPES}
                  validators={[]}
                />
              </div>
              <div className="col">
                <CheckboxGroup
                  header="Accounts"
                  onChange={(val) => setFilter(val, "accountTypes")}
                  values={ACCOUNT_TYPES.filter(({ value }) =>
                    filter.accountTypes.includes(value)
                  )}
                  alternatives={ACCOUNT_TYPES}
                  validators={[]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CheckboxGroup
                  header="Browsers"
                  onChange={(val) => setFilter(val, "browsers")}
                  values={BROWSER_TYPES.filter(({ value }) =>
                    filter.browsers.includes(value)
                  )}
                  alternatives={BROWSER_TYPES}
                  validators={[]}
                />
              </div>
              <div className="col">
                <CheckboxGroup
                  header="Devices"
                  onChange={(val) => setFilter(val, "devices")}
                  values={DEVICE_TYPES.filter(({ value }) =>
                    filter.devices.includes(value)
                  )}
                  alternatives={DEVICE_TYPES}
                  validators={[]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-between border-top pt-4">
                <Button variant="secondary" onClick={() => setIsOpen(false)}>
                  <strong>Collapse</strong> <Icon.ChevronUp size={14} />
                </Button>
                <Button
                  variant="negative"
                  className="hover"
                  onClick={() => remove(message)}
                >
                  <strong>Delete system message</strong>{" "}
                  <Icon.Close size={14} />
                </Button>
              </div>
            </div>
          </div>
        </AnimateHeight>
      </Card>
    </div>
  );
};
