import React from "react";
import { Router } from "./Router";

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="container">
        <Router />
      </div>
    </div>
  );
};

export default App;
