export interface User {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  accessToken: string;
  tokenId: string;
}

export const dataUser = {
  getUser: (): User | undefined => {
    const userString = sessionStorage.getItem("user");
    if (userString) {
      try {
        return JSON.parse(userString) as User;
      } catch {
        return undefined;
      }
    }
  },
  setUser: (user: User): User => {
    sessionStorage.setItem("user", JSON.stringify(user));
    return user;
  },
  clear: () => {
    sessionStorage.removeItem("user");
  },
};
