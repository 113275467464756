import { SNACKBAR_TYPES } from "@lysaab/ui-2";
import { dataUser } from "./dataUser";

export interface Message {
  id: number;
  enabled: boolean;
  status: SNACKBAR_TYPES;
  paths: string[];
  message: string;
  filter: Filters;
  updated: number;
  disableClose: boolean;
}

export interface Filters {
  accountTypes: AccountType[];
  types: CustomerType[];
  devices: Device[];
  browsers: Browser[];
}

export enum CustomerType {
  Visitor = "visitor",
  Person = "person",
  Corporation = "corporation",
}

export enum AccountType {
  ISK_SWE = "ISK_SWE",
  VP_SWE = "VP_SWE",
  DANICA_KF = "DANICA_KF",
}

export enum Browser {
  Edge = "edge",
  Safari = "safari",
  Chrome = "chrome",
  Firefox = "firefox",
  Other = "other",
}

export enum Device {
  Iphone = "ios",
  Android = "android",
  WindowsPhone = "windows-phone",
  Other = "other",
}

const BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
const FILENAME = process.env.REACT_APP_STORAGE_FILENAME;

export const dataMessages = {
  getMessages: (): Promise<Message[]> => {
    const version = new Date().getTime();
    return fetch(
      `https://storage.googleapis.com/${BUCKET}/${FILENAME}?version=${version}`
    ).then((response) => response.json() || []);
  },
  updateMessages: (messages: Message[]) => {
    const user = dataUser.getUser();
    const url = `https://www.googleapis.com/upload/storage/v1/b/${BUCKET}/o?uploadType=media&name=${FILENAME}`;

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.accessToken}`,
      },
      body: JSON.stringify(messages),
    }).then((response) => {
      if (response.status !== 200) {
        throw Error;
      }
    });
  },
};

export const generateNewMessage = () => {
  const timeOfCreation = new Date().getTime();
  const message: Message = {
    id: timeOfCreation,
    enabled: false,
    status: SNACKBAR_TYPES.ERROR,
    paths: [],
    message: "<span>New message</span>",
    filter: {
      types: Object.values(CustomerType),
      accountTypes: Object.values(AccountType),
      browsers: Object.values(Browser),
      devices: Object.values(Device),
    },
    updated: timeOfCreation,
    disableClose: false,
  };

  return message;
};
