import { AutocompleteOption } from "../autocomplete/Autocomplete";

export const PRESETS: AutocompleteOption[] = [
  {
    value: "/",
    label: "/",
  },
  {
    value: "/account-statement",
    label: "/account-statement",
  },
  {
    value: "/account/:accountId",
    label: "/account/:accountId",
  },
  {
    value: "/accounting",
    label: "/accounting",
  },
  {
    value: "/close-account",
    label: "/close-account",
  },
  {
    value: "/close-lysa-customer",
    label: "/close-lysa-customer",
  },
  {
    value: "/company-settings",
    label: "/company-settings",
  },
  {
    value: "/create-account",
    label: "/create-account",
  },
  {
    value: "/create-account-selection",
    label: "/create-account-selection",
  },
  {
    value: "/create-account/corporation",
    label: "/create-account/corporation",
  },
  {
    value: "/create-savings-account",
    label: "/create-savings-account",
  },
  {
    value: "/deposits",
    label: "/deposits",
  },
  {
    value: "/deposits/autogiro",
    label: "/deposits/autogiro",
  },
  {
    value: "/deposits/bankgiro",
    label: "/deposits/bankgiro",
  },
  {
    value: "/deposits/klarna",
    label: "/deposits/klarna",
  },
  {
    value: "/deposits/swish",
    label: "/deposits/swish",
  },
  {
    value: "/document/:group?/:document?",
    label: "/document/:group?/:document?",
  },
  {
    value: "/edit-allocation/:accountId?",
    label: "/edit-allocation/:accountId?",
  },
  {
    value: "/end-investment",
    label: "/end-investment",
  },
  {
    value: "/fees",
    label: "/fees",
  },
  {
    value: "/grid",
    label: "/grid",
  },
  {
    value: "/historic-transactions",
    label: "/historic-transactions",
  },
  {
    value: "/internal-transfer",
    label: "/internal-transfer",
  },
  {
    value: "/invite",
    label: "/invite",
  },
  {
    value: "/invoice/:contractNoteId",
    label: "/invoice/:contractNoteId",
  },
  {
    value: "/isk-transfer",
    label: "/isk-transfer",
  },
  {
    value: "/login",
    label: "/login",
  },
  {
    value: "/login-reset",
    label: "/login-reset",
  },
  {
    value: "/logout",
    label: "/logout",
  },
  {
    value: "/messages",
    label: "/messages",
  },
  {
    value: "/monthly",
    label: "/monthly",
  },
  {
    value: "/monthly/create",
    label: "/monthly/create",
  },
  {
    value: "/offline",
    label: "/offline",
  },
  {
    value: "/profile",
    label: "/profile",
  },
  {
    value: "/profile/change-email",
    label: "/profile/change-email",
  },
  {
    value: "/settings",
    label: "/settings",
  },
  {
    value: "/store",
    label: "/store",
  },
  {
    value: "/switch-user-selection",
    label: "/switch-user-selection",
  },
  {
    value: "/switch-user/:id",
    label: "/switch-user/:id",
  },
  {
    value: "/tax-information",
    label: "/tax-information",
  },
  {
    value: "/transactions",
    label: "/transactions",
  },
  {
    value: "/transfers-menu",
    label: "/transfers-menu",
  },
  {
    value: "/update-account",
    label: "/update-account",
  },
  {
    value: "/user-management",
    label: "/user-management",
  },
  {
    value: "/withdrawal/kf/request",
    label: "/withdrawal/kf/request",
  },
  {
    value: "/withdrawal/periodic",
    label: "/withdrawal/periodic",
  },
  {
    value: "/withdrawal/request",
    label: "/withdrawal/request",
  },
  {
    value: "/withdrawals",
    label: "/withdrawals",
  },
  {
    value: "/withdrawals/add/autogiro",
    label: "/withdrawals/add/autogiro",
  },
  {
    value: "/withdrawals/add/klarna",
    label: "/withdrawals/add/klarna",
  },
  {
    value: "/your-data",
    label: "/your-data",
  },
];
