import { Button } from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../Router";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from "react-google-login";
import { useCallback } from "react";
import { dataUser, User } from "../../data/dataUser";
import { FaGoogle } from "react-icons/fa";

interface Props {
  size?: "small" | "mini" | "medium" | undefined;
}

export const Login: React.VFC<Props> = ({ size }) => {
  const history = useHistory();

  const onSuccess = useCallback(
    (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if (!("profileObj" in response)) {
        console.log("Offline login not supported");
        return;
      }

      const user: User = JSON.parse(JSON.stringify(response.profileObj));
      const { accessToken, tokenId } = response;

      dataUser.setUser({
        ...user,
        accessToken,
        tokenId,
      });

      history.push(ROUTES.EDIT);
    },
    [history]
  );

  const onFailure = useCallback((response) => {
    console.log("Login failed", response);
  }, []);

  const { signIn, loaded } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT || "",
    scope: process.env.REACT_APP_GOOGLE_AUTH_SCOPE || "",
    onSuccess: onSuccess,
    onFailure: onFailure,
  });

  return (
    <Button
      size={size || "small"}
      variant="primary"
      onClick={signIn}
      disabled={!loaded}
    >
      <FaGoogle className="m-0 me-2" />
      Login
    </Button>
  );
};
