import { useEditor, EditorContent } from "@tiptap/react";
import "./MessageEditor.scss";
import { MessageEditorMenu } from "./MessageEditorMenu";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Paragraph from "@tiptap/extension-paragraph";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Heading from "@tiptap/extension-heading";
import Link from "@tiptap/extension-link";
import { useEffect } from "react";

interface Props {
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

export const MessageEditor: React.VFC<Props> = ({ value, label, onChange }) => {
  const editor = useEditor({
    extensions: [
      Document,
      Text,
      Paragraph,
      Bold,
      Italic,
      Heading,
      Link.configure({
        openOnClick: false,
        linkOnPaste: true,
      }),
    ],
    content: value,
  });

  useEffect(() => {
    if (editor === null) {
      return;
    }
    const changeHandler = () => onChange(editor.getHTML());
    editor.on("update", changeHandler);
    return () => {
      editor.off("update", changeHandler);
    };
  }, [editor, onChange]);

  return (
    <div>
      {label && <label>{label}</label>}
      <MessageEditorMenu editor={editor} />
      <EditorContent className="message-editor" editor={editor} />
    </div>
  );
};
