import { Button } from "@lysaab/ui-2";
import { Editor } from "@tiptap/core/dist/packages/core/src/Editor";
import { useCallback } from "react";
import { FaBold, FaItalic } from "react-icons/fa";
import "./MessageEditorMenu.scss";

interface Props {
  editor: Editor | null;
}

export const MessageEditorMenu: React.VFC<Props> = ({ editor }) => {
  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="message-editor-menu py-2">
      <Button
        size="small"
        variant="secondary"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "focus" : ""}
      >
        H1
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive("heading", { level: 4 }) ? "focus" : ""}
      >
        H2
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "focus" : ""}
      >
        <FaBold size={16}></FaBold>
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "focus" : ""}
      >
        <FaItalic size={16}></FaItalic>
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={setLink}
        className={editor.isActive("italic") ? "focus" : ""}
      >
        <small>Set link</small>
      </Button>
      {editor.isActive("link") && (
        <Button
          size="small"
          variant="secondary"
          onClick={() => editor.chain().focus().unsetLink().run()}
          className={editor.isActive("italic") ? "focus" : ""}
        >
          <small>Unset link</small>
        </Button>
      )}
    </div>
  );
};
