import { Button, Icon } from "@lysaab/ui-2";

interface Props {
  onSave: () => void;
  isLoading: boolean;
}

export const SaveButton: React.VFC<Props> = ({ onSave, isLoading }) => {
  return (
    <Button onClick={onSave} disabled={isLoading}>
      <strong>Save changes</strong>
      {isLoading ? (
        <div className="ms-3 spinner-border spinner-border-sm">
          <span className="sr-only"></span>
        </div>
      ) : (
        <Icon.Withdrawal size={16} />
      )}
    </Button>
  );
};
