import { Icon } from "@lysaab/ui-2";
import { Flag } from "../Flag";
import { Country } from "./PathsEditor";
import "./PathTag.scss";

import cx from "classnames";

interface Props {
  path: string;
  countries: Country[];
  rawPath: string;
  onRemove: (path: string) => void;
  onToggleCountry: (country: Country) => void;
  onToggleAll: () => void;
}

export const PathTag: React.VFC<Props> = ({
  path,
  countries,
  rawPath,
  onRemove,
  onToggleCountry,
  onToggleAll,
}) => {
  const COUNTRIES = Object.values(Country);

  return (
    <div className="path-tag me-2 mb-3 rounded-pill d-flex align-items-center">
      <div className="d-flex py-2 ms-3 me-1 text-nowrap">
        <div className="me-2 border-end border-primary">
          <span onClick={onToggleAll}>
            <Flag
              className={cx(
                {
                  "opacity-25": countries.length !== 0,
                },
                "me-2",
                "path-tag-country-select"
              )}
              code="eu"
            />
          </span>
          {COUNTRIES.map((country) => (
            <span
              key={country}
              className={cx(
                { "opacity-25": !countries.includes(country) },
                "me-2",
                "path-tag-country-select"
              )}
              onClick={() => onToggleCountry(country)}
            >
              <Flag code={country.toLowerCase()} />
            </span>
          ))}
        </div>
        <span>{rawPath}</span>
      </div>
      <div
        className="path-tag-remove-button px-3 py-2"
        onClick={() => onRemove(path)}
      >
        <Icon.Close size={14} />
      </div>
    </div>
  );
};
