import { Logo } from "@lysaab/ui-2/components/icons/Logo";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../Router";
import { Login } from "../login/Login";
import { Logout } from "../logout/Logout";

export const Header: React.VFC = () => {
  const location = useLocation();

  const isAuthenticated = location.pathname !== ROUTES.AUTH;

  return (
    <div className="py-3 d-flex align-items-center justify-content-between mb-4">
      <div className="d-flex align-items-center">
        <Logo size={32} />
        <h4 className="m-0 ms-3 ps-3 border-start border-primary">
          System messages administration
        </h4>
      </div>
      {isAuthenticated ? <Logout /> : <Login />}
    </div>
  );
};
