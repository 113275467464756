import { Button } from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../Router";
import { useGoogleLogout } from "react-google-login";
import { useCallback } from "react";
import { dataUser } from "../../data/dataUser";

export const Logout: React.VFC = () => {
  const history = useHistory();
  const { signOut, loaded } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT || "",
    scope: process.env.REACT_APP_GOOGLE_AUTH_SCOPE || "",
    // Throws error unless specified
    onLogoutSuccess: () => null,
  });

  const logout = useCallback(() => {
    dataUser.clear();
    signOut();
    history.push(ROUTES.AUTH);
  }, [history, signOut]);

  return (
    <Button size="small" variant="primary" onClick={logout} disabled={!loaded}>
      Logout
    </Button>
  );
};
