import {
  Button,
  Icon,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  StatusLabel,
  STATUS_TYPE,
  useBeforeunload,
} from "@lysaab/ui-2";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useHistory } from "react-router-dom";
import { FloatingAction } from "../components/floatingSaveAction/FloatingAction";
import { MessageListItem } from "../components/message/MessageListItem";
import { SaveButton } from "../components/SaveButton";
import {
  Message,
  dataMessages,
  generateNewMessage,
} from "../data/dataMessages";
import { useIsMount } from "../hooks/useIsMount";
import { ROUTES } from "../Router";

const isDevelopment = process.env.NODE_ENV === "development";

export const ManagePage: React.VFC = () => {
  const isMount = useIsMount();
  const history = useHistory();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (isModified && !isDevelopment) {
        event.returnValue =
          "You have unsaved changes, are you sure you want to leave";
      }
    },
    [isModified]
  );

  useBeforeunload(handleUnload);

  const enabledMessages = messages.filter((message) => message.enabled).length;
  const totalMessages = messages.length;

  useEffect(() => {
    dataMessages
      .getMessages()
      .then((messages) => {
        setMessages(messages);
        setIsLoading(false);
        setIsModified(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (!isMount) {
      setIsModified(true);
    }
  }, [isMount, messages]);

  const updateItem = useCallback(
    (updatedMessage: Message) => {
      const newMessages = messages.map((message) =>
        message.id === updatedMessage.id ? { ...updatedMessage } : message
      );
      setMessages(newMessages);
    },
    [messages]
  );

  const removeItem = useCallback(
    ({ id }: Message) => {
      const newMessages = messages.filter((message) => message.id !== id);
      setMessages(newMessages);
    },
    [messages]
  );

  const addItem = useCallback(() => {
    const newMessage = generateNewMessage();
    setMessages([...messages, newMessage]);
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [messages]);

  const save = useCallback(() => {
    setIsSaving(true);
    dataMessages
      .updateMessages(messages)
      .then(() => setIsModified(false))
      .catch(() => {
        history.push(ROUTES.AUTH);
      })
      .finally(() => setIsSaving(false));
  }, [history, messages]);

  return (
    <div className="pb-5">
      <h2 className="text-center my-5">Manage system messages</h2>
      <AnimateHeight animateOpacity={true} height={isModified ? "auto" : 0}>
        <div className="d-flex mb-3">
          <div className="flex-grow-1 me-4">
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              You have unsaved changes
            </Snackbar>
          </div>
          <SaveButton onSave={save} isLoading={isSaving} />
        </div>
      </AnimateHeight>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="d-flex align-items-end">
          <span className="me-2 d-block">Messages</span>
          <div className="opacity-75">
            <StatusLabel className="m-0" icon={false} type={STATUS_TYPE.INFO}>
              <span className="opacity-75">
                {enabledMessages} / {totalMessages} active
              </span>
            </StatusLabel>
          </div>
        </h3>
        <Button
          disabled={isLoading}
          size="small"
          variant="secondary"
          onClick={addItem}
        >
          Add new
          <Icon.Add size={12} />
        </Button>
      </div>
      {!isLoading ? (
        <div className="pb-5">
          {messages.map((message) => (
            <MessageListItem
              key={message.id}
              message={message}
              update={updateItem}
              remove={removeItem}
            />
          ))}
        </div>
      ) : (
        <Spinner />
      )}
      {isModified && (
        <FloatingAction>
          <SaveButton onSave={save} isLoading={isSaving} />
        </FloatingAction>
      )}
    </div>
  );
};
