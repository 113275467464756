import { Login } from "../components/login/Login";

export const AuthPage: React.VFC = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <h1 className="text-center mt-5">Welcome</h1>
      <p className="mb-3">Please login to continue</p>
      <Login size="medium" />
    </div>
  );
};
