import { TextInput } from "@lysaab/ui-2";
import { useCallback, useEffect, useRef, useState } from "react";
import "./Autocomplete.scss";

interface Props {
  value: string;
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  values: AutocompleteOption[];
}

export interface AutocompleteOption {
  value: string;
  label: string;
}

export const Autocomplete: React.VFC<Props> = ({
  value,
  values,
  onChange,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [selectedItem, setSelectedItem] = useState(-1);

  const setSelected = useCallback(
    (index: number) => {
      const clampedIndex = Math.min(Math.max(index, -1), values.length - 1);
      setSelectedItem(clampedIndex);
    },
    [values.length]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setSelected(selectedItem + 1);
          break;
        case "ArrowUp":
          event.preventDefault();
          setSelected(selectedItem - 1);
          break;
        case "Tab":
          setSelected(selectedItem + 1);
          break;
        case "Esc":
          setIsOpen(false);
          break;
        case "Escape":
          setIsOpen(false);
          break;
      }
    },
    [selectedItem, setSelected]
  );

  useEffect(() => {
    if (selectedItem === -1) {
      return inputRef.current?.focus();
    }

    const element = listRef.current?.children.item(selectedItem)
      ?.children[0] as HTMLButtonElement | undefined;

    if (element) {
      element.focus();
    }
  }, [selectedItem, setSelected]);

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const ref = wrapperRef.current;
      if (ref && ref !== event.target && !ref.contains(event.target as any)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", onClick);
    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [wrapperRef, setIsOpen]);

  useEffect(() => {
    setIsOpen(value.length > 0);
  }, [value, values]);

  const select = useCallback(
    (item: AutocompleteOption) => {
      setIsOpen(false);
      onSelect(item.value);
    },
    [onSelect]
  );

  useEffect(() => {
    setSelected(-1);
  }, [isOpen, setSelected]);

  return (
    <div
      className="autocomplete-input"
      ref={wrapperRef}
      onKeyDown={handleKeyDown}
    >
      <div onClick={() => setIsOpen(true)}>
        <TextInput
          value={value}
          label="Pages to show on"
          onChange={onChange}
          placeholder="Type for suggestions..."
          ref={inputRef}
        />
      </div>
      {isOpen && (
        <ul className="autocomplete-input-list" ref={listRef}>
          {values.length > 0 ? (
            values.map((item, index) => (
              <li key={index}>
                <button onClick={() => select(item)}>{item.label}</button>
              </li>
            ))
          ) : (
            <li>
              <button>No matching routes...</button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
