import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthPage } from "./pages/AuthPage";
import { ManagePage } from "./pages/ManagePage";
import { Header } from "./components/header/Header";

export const ROUTES = {
  AUTH: "/",
  EDIT: "/manage",
};

export class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path={ROUTES.AUTH} component={AuthPage} />
          <Route path={ROUTES.EDIT} component={ManagePage} />
          <Redirect to={ROUTES.AUTH} />
        </Switch>
      </BrowserRouter>
    );
  }
}
