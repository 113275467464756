import {
  Icon,
  Snackbar,
  SNACKBAR_TYPES,
  StatusLabel,
  STATUS_TYPE,
} from "@lysaab/ui-2";
import { ArrowDown } from "@lysaab/ui-2/components/icons/ArrowDown";
import { ArrowUp } from "@lysaab/ui-2/components/icons/ArrowUp";
import "./MessageListItemHeader.scss";

interface Props {
  enabled: boolean;
  message: string;
  type: SNACKBAR_TYPES;
  isOpen: boolean;
  toggle: (state: boolean) => void;
}

export const MessageListItemHeader: React.VFC<Props> = ({
  isOpen,
  message,
  type,
  enabled,
  toggle,
}) => {
  return (
    <div
      className="message-list-item-header d-flex justify-content-between align-items-center p-3"
      onClick={() => toggle(!isOpen)}
    >
      <div className="d-flex w-100">
        <StatusLabel
          className="rounded-pill mr-2 py-3 m-0"
          type={enabled ? STATUS_TYPE.SUCCESS : STATUS_TYPE.ERROR}
          icon={false}
        >
          {enabled ? (
            <Icon.Checkmark color="green" size={12} />
          ) : (
            <Icon.Close color="red" size={12} />
          )}
        </StatusLabel>
        <div className="mx-3 w-100">
          <Snackbar type={type} icon={true}>
            <div className="system-message-content">
              <span dangerouslySetInnerHTML={{ __html: message }}></span>
            </div>
          </Snackbar>
        </div>
      </div>
      <div className="ps-3">
        {isOpen ? (
          <button className="transparent-button">
            <strong className="me-2 opacity-75">Hide</strong>{" "}
            <ArrowUp size={20} />
          </button>
        ) : (
          <button className="transparent-button">
            <strong className="me-2 opacity-75">View</strong>{" "}
            <ArrowDown size={20} />
          </button>
        )}
      </div>
    </div>
  );
};
