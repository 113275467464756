import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { useCallback, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Autocomplete, AutocompleteOption } from "../autocomplete/Autocomplete";
import { PathTag } from "./PathTag";
import { PRESETS } from "./Presets";

export enum Country {
  SWEDEN = "SE",
  DENMARK = "DK",
  FINLAND = "FI",
  GERMANY = "DE",
}

interface ExtendedPath {
  path: string;
  rawPath: string;
  countries: Country[];
}

const AUTOCOMPLETE_OPTIONS = PRESETS.sort((a, b) =>
  a.value.localeCompare(b.value)
);

const COUNTRIES = Object.values(Country);

const generateURL = (path: string, countries: Country[] = []) =>
  countries.length
    ? `/:country(${countries.join("|").toLowerCase()})${path}`
    : `/:country${path}`;

interface Props {
  value: string[];
  onChange: (paths: string[]) => void;
}

export const PathsEditor: React.VFC<Props> = ({ value, onChange }) => {
  const [pathInput, setPathInput] = useState<string>("");
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [matchingValues, setMatchingValues] =
    useState<AutocompleteOption[]>(AUTOCOMPLETE_OPTIONS);
  const [extendedPaths, setExtendedPaths] = useState<ExtendedPath[]>([]);

  const updatePath = useCallback((path: string) => {
    setPathInput(path);
    setIsDuplicate(false);
  }, []);

  const removePath = useCallback(
    (paths: string) => {
      const newPaths = value.filter((p) => !paths.includes(p));
      onChange(newPaths);
    },
    [onChange, value]
  );

  const addPath = useCallback(
    (path: string) => {
      if (path && !value.includes(path)) {
        onChange([...value, generateURL(path)]);
        setPathInput("");
      } else {
        setIsDuplicate(true);
      }
    },
    [onChange, value]
  );

  const toggleCountry = useCallback(
    ({ countries, rawPath, path }: ExtendedPath, country: Country) => {
      const index = value.findIndex((p) => p === path);
      const exists = countries.includes(country);
      if (exists) {
        countries = countries.filter((c) => c !== country);
      } else {
        countries.push(country);
      }

      const newPaths = [...value];
      newPaths[index] = generateURL(rawPath, countries);
      onChange(newPaths);
    },
    [onChange, value]
  );

  const toggleUniversal = useCallback(
    ({ rawPath, path }: ExtendedPath) => {
      const index = value.findIndex((p) => p === path);
      const newPaths = [...value];
      newPaths[index] = generateURL(rawPath, []);
      onChange(newPaths);
    },
    [onChange, value]
  );

  useEffect(() => {
    const extendedPaths: ExtendedPath[] = value.map((value) => {
      const [, countries, path] = value.split(
        new RegExp(/(\/:country.*\)|\/:country)(\/.*)/)
      );
      return {
        path: value,
        rawPath: path || value,
        countries: countries
          ? COUNTRIES.filter((country) =>
              countries.includes(country.toLowerCase())
            )
          : [],
      };
    });
    setExtendedPaths(extendedPaths);
  }, [value]);

  useEffect(() => {
    if (pathInput === "") {
      setMatchingValues(
        AUTOCOMPLETE_OPTIONS.filter(
          (option) =>
            !extendedPaths.find(({ rawPath }) => option.value === rawPath)
        )
      );
    } else {
      const matches = AUTOCOMPLETE_OPTIONS.filter((option) =>
        option.value.includes(pathInput.toLowerCase())
      ).filter(
        (option) =>
          !extendedPaths.find(({ rawPath }) => option.value === rawPath)
      );
      setMatchingValues(matches);
    }
  }, [extendedPaths, pathInput, value]);

  return (
    <div className="mb-4">
      <div className="d-flex align-items-end">
        <div className="flex-grow-1 me-4">
          <Autocomplete
            value={pathInput}
            onChange={updatePath}
            onSelect={addPath}
            values={matchingValues}
          />
        </div>
      </div>
      <AnimateHeight animateOpacity={true} height={isDuplicate ? "auto" : 0}>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon={true}>
          Same path already exists
        </Snackbar>
      </AnimateHeight>
      <div className="d-flex flex-wrap">
        {extendedPaths.map((compoundPath, index) => (
          <PathTag
            key={compoundPath.path + index}
            onRemove={removePath}
            onToggleCountry={(country) => toggleCountry(compoundPath, country)}
            onToggleAll={() => toggleUniversal(compoundPath)}
            {...compoundPath}
          />
        ))}
      </div>
    </div>
  );
};
